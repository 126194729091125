import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import Dark from "./buchhaltung-dark.svg"
import White from "./buchhaltung-white.svg"

const IconWhite = styled(White)`
    width: ${props => props.width};
    height: ${props => props.height};
`
const IconDark = styled(Dark)`
    width: ${props => props.width};
    height: ${props => props.height};
`

export const BuchhaltungIcon = ({ white, width, height }: { white?: boolean, width: string, height: string }) => (
    white ? <IconWhite width={width} height={height} /> : <IconDark width={width} height={height} />
)

export const BuchhaltungIconScalable = ({ white, className }: { white?: boolean, className: string }) => {
    if (white) return (
        <StaticImage
            src="../images/buchhaltung-icon-white.png"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Image"
            placeholder="blurred"
            className={className}
        />
    )
    else return (
        <StaticImage
            src="../images/buchhaltung-icon.png"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Image"
            placeholder="blurred"
            className={className}
        />
    )

}
