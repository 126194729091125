import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import SteuerberatungDark from "./steuerberatung-dark.svg"
import SteuerberatungWhite from "./steuerberatung-white.svg"

const IconWhite = styled(SteuerberatungWhite)`
    width: ${props => props.width};
    height: ${props => props.height};
`
const IconDark = styled(SteuerberatungDark)`
    width: ${props => props.width};
    height: ${props => props.height};
`

export const SteuerberatungIcon = ({ white, width, height }: { white?: boolean, width: string, height: string }) => (
    white ? <IconWhite width={width} height={height} /> : <IconDark width={width} height={height} />
)

export const SteuerberatungIconScalable = ({ white, className }: { white?: boolean, className: string }) => {
    if (white) return (
        <StaticImage
            src="../images/steuerberatung-icon-white.png"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Image"
            placeholder="blurred"
            className={className}
        />
    )
    else return (
        <StaticImage
            src="../images/steuerberatun-icon.png"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Image"
            placeholder="blurred"
            className={className}
        />
    )
}
